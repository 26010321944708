import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export class Firebase {
  constructor() {
    const app = firebase.initializeApp(config);
    this.auth = app.auth();
    this.firestore = app.firestore();
    this.docRef = this.firestore.collection("companyLogos").doc("logos");
    this.voteRef = this.firestore.collection("votingData").doc("votes");
    this.addVertRef = this.firestore.collection("addSense").doc("list");
  }

  readLogosFromFireStore = () => {
    return this.docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          return doc.data();
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  saveLogosInFireStore = (companyLogos, setLoading) => {
    this.docRef
      .update({ logosList: JSON.stringify(companyLogos) })
      .then(() => {
        console.log("Document successfully written!");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
      });
  };

  saveVotesInFireStore = (votingData) => {
    this.voteRef
      .update({ votes: votingData })
      .then(() => {
        console.log("Votes Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  // saveAdvertisementsInFireStore = (advertisements) => {
  //   this.addVertRef
  //     .update({ list: advertisements })
  //     .then(() => {
  //       console.log("Advertisements Document successfully written!");
  //     })
  //     .catch((error) => {
  //       console.error("Error writing document: ", error);
  //     });
  // };

  readAdvertisementsFromFireStore = () => {
    return this.addVertRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  readVotesFromFireStore = () => {
    return this.voteRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Votes data:", doc.data());
          return doc.data();
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  signIn = async (email, password) =>
    await this.auth.signInWithEmailAndPassword(email, password);

  authState = (cb) => this.auth.onAuthStateChanged(cb);

  signUp = async (email, password) =>
    await this.auth.createUserWithEmailAndPassword(email, password);

  forgotPassword = async (email) =>
    await this.auth.sendPasswordResetEmail(email);

  getDocsRealTime = (cb, fallback, options) => {
    const {
      collection,
      // dayStart,
      // dayEnd,
      sortBy,
      order,
      status,
    } = options;
    let query = this.firestore.collection(collection);

    // if (dayStart) query = query.where(sortBy, ">=", dayStart);

    // if (dayEnd) query = query.where(sortBy, "<=", dayEnd);

    if (status) query = query.where("status", "==", status);

    query = query.orderBy(sortBy, order);

    return query.onSnapshot((snap) => {
      this.snapShotCallBack(snap, cb);
    }, fallback);
  };

  getDocCount = (cb, fallback) =>
    this.firestore.doc("metadata/stocks").onSnapshot((doc) => {
      cb(doc.data().count);
    }, fallback);

  snapShotCallBack = (snap, cb) => {
    const data = [];
    snap.forEach((node) => {
      data.push(node.data());
    });
    cb(data);
  };

  getDocCount = (cb, fallback) =>
    this.firestore.doc("counters/webhook").onSnapshot((doc) => {
      cb(doc.data().count);
    }, fallback);

  signOut = () => this.auth.signOut();

  resetPassword = async (pass) =>
    await this.auth.currentUser.updatePassword(pass);
}
